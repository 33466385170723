<template>
  <div class="subpage-container">
    <div class="slick-container fullscreen">
      <VueSlickCarousel
        ref="slick"
        :infinite="false"
        :arrows="false"
        @afterChange="onAfterChange"
      >
        <div class="slide-item">
          <div class="slide-container--text">
            <div class="text-container">
              <h1>圖像誌</h1>
              <p>
                關於中亞音樂的幾幅圖像誌（iconography），呈現了不同的音樂場景。
              </p>
            </div>
          </div>
        </div>
        <div
          class="slide-item"
          v-for="(item, i) in items"
          :key="`compare-${i}`"
        >
          <div class="image-caption-container">
            <div class="caption-container">
              <h2 class="caption-title">{{ item.title }}</h2>
              <div class="caption-content">{{ item.content }}</div>
            </div>
          </div>
          <img
            :src="
              require(`@/assets/images/gallery2/iconography/iconography-${i}.jpg`)
            "
            class="slide-image"
          />
        </div>
      </VueSlickCarousel>
      <div
        :class="{
          'btn-controls': true,
        }"
      >
        <div
          v-if="currentSlide != 0"
          :class="{ 'btn-prev': true }"
          @click="prev"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
        <div
          v-if="currentSlide < items.length"
          :class="{ 'btn-next': true }"
          @click="next"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  data() {
    return {
      currentSlide: 0,
      items: [
        {
          title: "師徒制",
          content:
            "畫中師傅用口傳心授的方式教徒弟學習如何演奏彈撥爾。舊時的樂器演奏絕大部份都是通過口傳心授的方式傳承下來，並沒有現代意義上那種將音符和節奏記錄得很精準的樂譜。口傳心授的形式，給了音樂演奏一定的靈活性與即興性。舊時很多演奏家同時也是作曲家。",
        },
        {
          title: "庫布孜",
          content:
            "畫中遊牧部落的薩滿在拉奏著庫布孜。庫布孜被認為是具有守護力量的器具，可以作為薩滿和靈溝通的一個中介。通過薩滿儀式，薩滿邊唱邊演奏庫布孜，有治療的作用，也可以保護社群。薩滿的演唱也可以是史詩的一個載體。庫布孜傳統上的特色是利用泛音和擬聲，模擬圖騰的動物聲音、形態、動作。到了伊斯蘭教盛行時期，融入了蘇菲神秘主義的一些比較全情投入的色彩，因為兩者都有超脫精神的意境。",
        },
        {
          title: "木卡姆",
          content:
            "畫中是木卡姆小組合奏。木卡姆在阿拉伯文裡意思是place，position，station。木卡姆最接近西樂的意思是melodic mode或melody type。十九世紀後「木卡姆」也指套曲，如今講起木卡姆通常會指套曲。河中流域的三個汗國布哈拉、希瓦、浩罕各自發展出自己傳統風格特色的木卡姆。木卡姆屬於中亞古典文學遺產的一部分，其套曲裡有許多古代偉大詩人（如魯米、海菲茲）的詩。",
        },
        {
          title: "口弦琴",
          content:
            "畫中的母親在氈房前教兒子口弦琴。口弦琴是歐亞草原上很流行的樂器，其樂曲多表達動物、大自然和遊牧生活。歷史上女性對口弦琴發展很重要，母親會教孩子演奏口弦琴，這在兒童的教育和其成長裡的音樂生活都有重要位置。",
        },
      ],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    this.$store.commit("updateCurrentSection", 2);

    this.myMusic = new Howl({
      src: ["../../../music/gallery2/iconography.mp3"],
      loop: true,
    });

    this.myMusic.once("load", () => {
      this.myMusic.stop();
      this.myMusic.play();
    });
  },
  destroyed() {
    this.myMusic?.once("fade", () => {
      this.myMusic.unload();
    });
    this.myMusic?.fade(this.myMusic.volume(), 0, 1000);
  },
  methods: {
    onAfterChange(slideIndex) {
      this.currentSlide = slideIndex;
    },
    prev() {
      this.$refs.slick.prev();
    },
    next() {
      this.$refs.slick.next();
    },
  },
};
</script>

<style lang="scss"></style>
